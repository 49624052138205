<template>
<a href="javascript:;" class="cc-card g-position-r" @click="toJump">
	<div class="g-texta-c g-marginb-20" style="margin-top: 40px">
		<img style="width: 37px; height: 44px" :src="iconUrl"/>
	</div>
	<div class="cc-card-1 g-texta-c g-fonts-18 g-letters-65 g-fontw-600 g-marginb-30">
		{{title}}
	</div>
	<div class="cc-card-2 g-color-gray g-lineh-24" style="letter-spacing: 1.42px; margin-bottom: 49px; height: 72px">
		{{text}}
	</div>
	<Link v-if="jump" :to="to" class="cc-card-3 g-fonts-12 g-lineh-24" single>查看详情</Link>
	<div class="cc-card-4 g-position-a g-bottom-0 g-right-0">
		<div style="width: 128px; height: 114px; overflow:hidden" >
			<img style="width: 154px; height: 136px" :src="img2"/>
		</div>
	</div>
</a>
</template>
<script>
const icons = [
	require('@/assets/img/products/product-xindai.svg'),
	require('@/assets/img/products/product-fengkong.svg'),
	require('@/assets/img/products/product-cuishou.svg'),
	require('@/assets/img/products/product-ABS.svg'),
]
const icons2 = [
	require('@/assets/img/products/product-xindai2.svg'),
	require('@/assets/img/products/product-fengkong2.svg'),
	require('@/assets/img/products/product-cuishou2.svg'),
	require('@/assets/img/products/product-ABS2.svg'),
]
export default {
	props: {
		icon: {
			type: Number,
			default: 0
		},
		iconUrl: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		to: {
			type: String,
			default: ''
		},
		jump: {
			type: Boolean,
			default: true
		},
	},
	computed: {
		img1() {
			return icons[this.icon]
		},
		img2() {
			return icons2[this.icon]
		},
	},
	methods: {
		toJump() {
			if (this.jump) {
				this.$router.push(this.to)
			}
		}
	}
}
</script>
<style lang="less" scoped>
@import "@/assets/styles/variables.less";
.cc-card {
	display: block;
	min-height: 340px;
	border: 1px solid #E4E7ED;
	border-radius: 5px;
	padding-left: 37px;
	padding-right: 37px;
	transition: all ease-in-out .3s;
	.cc-card-4 {
		transition: all ease-in-out .3s;
		opacity: 1;
	}
	.cc-card-3 {
		letter-spacing: 1.22px;
	}
	&:hover {
		background: fadeout(@primary-color, 20%);
		border-color: fadeout(@primary-color, 20%);
		box-shadow: 0 2px 22px 2px fadeout(@primary-color, 70%);
		.cc-card-1 {
			color: #fff;
		}
		.cc-card-2 {
			color: #fff;
			opacity: 0.8;
		}
		.cc-card-3 {
			color: #fff;
		}
		.cc-card-4 {
			opacity: 0.2;
		}
	}
}
</style>
