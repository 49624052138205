<template>
<div class="p-products" >
<Wrapper :bg-img="img" min-height="380px">
	<div style="height: 120px"/>
	<div style="font-size: 48px" class="g-letters-58 g-marginb-28">洲联产品服务</div>
	<div class="g-fonts-24 g-letters-29 g-opacity-08 g-color-gray">汇集洲联数科产品与服务体系介绍，帮您快速了解产品功能，<br>准确定位服务需求，以科技创新赋能政企机构智慧化升级。</div>
</Wrapper>
<div class="p-products-tab">
	<div style="height: 52px">
	<!--<div class="g-bg-white g-zindex-1 p-products-tab" :class="{'g-position-f': fixed, 'g-left-0': fixed, 'g-right-0': fixed, 'p-top-80': fixed }">-->
	<div class="g-bg-white g-zindex-1 p-products-tab" :class="{'g-position-f': fixed, 'g-left-0': fixed, 'g-right-0': fixed, 'p-top-0': fixed }">
		<a-tabs size="large" :tabBarGutter="120" v-model="activeKey" @tabClick="handleTabClick">
			<a-tab-pane key="1" tab="小微云工厂">
			</a-tab-pane>
			<a-tab-pane key="2" tab="产业数字化">
			</a-tab-pane>
		</a-tabs>
	</div>
	</div>
	<Wrapper style="padding-bottom: 80px">
		<div style="height: 65px"/>
		<div class="g-position-r g-texta-c" style="margin-bottom: -38px">
			<img style="width: auto; height: 90px" src="@/assets/img/products/CreditTech.svg"/>
		</div>
		<DecoratedTitle class="g-position-r g-fonts-24 g-letters-29 g-marginb-22" center>小微云工厂</DecoratedTitle>
		<div class="g-fonts-18 g-letters-69 g-marginb-35 g-texta-c">信贷产品云</div>
		<a-row :gutter="20">
			<a-col v-for="item in list1" :key="item.title" :span="6">
				<Card class="p-products-ani1 g-marginb-20" :icon="item.icon" :iconUrl="item.iconUrl" :title="item.title" :text="item.text" :to="item.to"/>
			</a-col>
		</a-row>
		<div style="height: 60px"/>
		<div class="g-fonts-18 g-letters-69 g-marginb-35 g-texta-c">运营产品云</div>
		<a-row :gutter="20">
			<a-col v-for="item in list2" :key="item.title" :span="6">
				<Card class="p-products-ani2 g-marginb-20" :icon="item.icon" :iconUrl="item.iconUrl" :title="item.title" :text="item.text" :to="item.to"/>
			</a-col>
		</a-row>
		<div style="height: 60px"/>
		<div class="g-fonts-18 g-letters-69 g-marginb-35 g-texta-c">组件与数据服务</div>
		<a-row :gutter="20">
			<a-col v-for="item in list22" :key="item.title" :span="6">
				<Card class="p-products-ani22 g-marginb-20" :icon="item.icon" :iconUrl="item.iconUrl" :title="item.title" :text="item.text" :to="item.to"/>
			</a-col>
		</a-row>
	</Wrapper>
	<Wrapper style="padding-bottom: 80px" minHeight="624px">
		<!-- <div style="height: 65px"/> -->
		<div class="g-position-r g-texta-c" style="margin-bottom: -38px">
			<img style="width: auto; height: 90px" src="@/assets/img/common/IndustrialDigitization.svg"/>
		</div>
		<DecoratedTitle class="g-position-r g-fonts-24 g-letters-29 g-marginb-22" center>产业数字化</DecoratedTitle>
		<div class="g-fonts-18 g-letters-69 g-marginb-35 g-texta-c"></div>
		<a-row :gutter="20">
			<a-col v-for="item in list3" :key="item.title" :span="6">
				<Card :jump="item.jump" class="p-products-ani3 g-marginb-20" :icon="item.icon" :iconUrl="item.iconUrl" :title="item.title" :text="item.text" :to="item.to"/>
			</a-col>
		</a-row>
	</Wrapper>
</div>
</div>
</template>
<script>
import Card from './components/Card'
import { cloudFactory, digitize, intelligenceAgro, productDetails } from '@/views/products/config'
const icons = [
	require('@/assets/img/products/product-xindai.svg'),
	require('@/assets/img/products/product-fengkong.svg'),
	require('@/assets/img/products/product-cuishou.svg'),
	require('@/assets/img/products/product-ABS.svg'),
]
const to = (id) => {
	return `/product?p=${id}`
}

export default {
	name: 'Products',
	components: { Card },
	data() {
		return {
			activeKey: '1',
			fixed: false,
			img: require('@/assets/img/products/product-banner.png')
		}
	},
	computed: {
		list1() {
			const list = this.transformList(cloudFactory.loan)
			return this.transform(list)
		},
		list2() {
			const list = this.transformList(cloudFactory.operation)
			return this.transform(list)
		},
		list22() {
			const list = this.transformList(cloudFactory.service)
			return this.transform(list)
		},
		list3() {
			const list = this.transformList(digitize).map(item => ({ ...item, jump: false }))
			return this.transform(list)
		},
		list4() {
			const list = this.transformList(intelligenceAgro.platforms)
			return this.transform(list)
		},
		list5() {
			const list = this.transformList(intelligenceAgro.hardware)
			return this.transform(list)
		},
	},
	mounted() {
		this.$root.$on('scroll', this.handleScroll)
	},
	beforeDestroy() {
		this.$root.$off('scroll', this.handleScroll)
	},
	methods: {
		handleTabClick(key) {
			// this.activeKey
			const top = key === '1' ? 380 : key === '2' ? 2700 : 3500
			this.$scrollTo({
				top: top,
				behavior: 'smooth'
			})
		},
		slice(str) {
			if (typeof str !== 'string') {
				return ''
			}
			return str.length > 45 ? str.substring(0, 40) + '...' : str
		},
		transformList(list) {
			return list.map(item => {
				return {
					title: item.name,
					iconUrl: item.icon,
					text: productDetails[item.id] ? this.slice(productDetails[item.id].desc) : '',
					to: to(item.id)
				}
			})
		},
		transform(list) {
			return list.map((item, index) => {
				const i = index % icons.length
				const ret = { ...item }
				ret.icon = i
				return ret
			})
		},
		/**
		 * @function 窗口滚定监听事件
		 * @param {int} top 窗口滚动高度
		 *
		 */
		handleScroll(top) {
			if (top >= 380) {
				if (!this.fixed) {
					this.fixed = true
				}
			} else {
				if (this.fixed) {
					this.fixed = false
				}
			}
			if (top >= 0 && top < 2700) {
				if (this.activeKey !== '1') {
					this.activeKey = '1'
				}
			} else if (top >= 2700 && top < 3500) {
				if (this.activeKey !== '2') {
					this.activeKey = '2'
				}
			} else {
				if (this.activeKey !== '3') {
					this.activeKey = '3'
				}
			}
		}
	}
}
</script>
<style lang="less" scoped>
.p-top-80 {
	top: 80px;
}
.p-top-0 {
	top: 0;
}
</style>
<style lang="less">
.p-products-tab {
	.ant-tabs-bar {
		margin: 0;
	}
	.ant-tabs-tab {
		font-size: 14px;
	}
	.ant-tabs-ink-bar {
		bottom: 0;
		background: transparent;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 20%;
			right: 20%;
			background: #005FFF;

		}
	}
	.ant-tabs-nav-wrap {
		max-width: 1280px;
		margin: 0 auto;
	}
}
</style>

